.mainContent {
	position: absolute;
	padding-left: 5%;
	margin-top: 3%;
}

.Uploader__container {
	padding-left: 5%;
	padding-right: 5%;
	margin-top: 3%;
	width: auto;
	flex-direction: column;
	align-items: center;
}
