.profileButton {
    position: absolute;
    right: 0%;
    top: -2%;
    height: 64px;
    right: 0;
    text-align: right;
}

.profileClick {
    width: 150px;
    height: 100%;
    top: 1%
}