/* @import '~antd/dist/antd.css'; */

.ChartPage__header {
	display: flex;
	justify-content: flex-end;
	padding-bottom: 20px;
	padding-right: 20px;
  }

  .DirectoryPageWrapper {
	width: 90%;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
  }
  .collection-create-form_last-form-item {
	margin-bottom: 0;
  }