/* @import '~antd/dist/antd.css'; */
.PageWrapper {
  width: 87%;
  min-width: 900px;
  margin: 0 auto;
}

.Page__title {
  padding: 29px 10px 20px 30px;
  font-size: 28px;
  line-height: 39px;
}

.ant-input,
.ant-select,
.ant-input-number,
.ant-btn {
  font-size: 11px;
}

.ant-picker-input>input {
  font-size: 11px;
  text-align: center;
}

.ant-table-filter-column {
  flex-direction: row;
}

.shortcutComment {
  max-width: 100px;
  padding: 2px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tableInput:focus,
.ant-input-focused {
  border-color: none;
  box-shadow: none;
  border-right-width: 0;
  outline: none;
}

.tableInput:hover {
  border-color: none;
  border-right-width: 0;
  outline: none;
}

.tableInput {
  border: none;
  padding: 0;
}

.ant-form-item-control-input {
  min-height: 26px;
}

.paginationWrapper {
  margin: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowPagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowPagination__size {
  font-size: 20px;
}

.pagination__countPage {
  padding: 0 10px;
}

.pagination__limitWrapper {
  margin: 0 20px;
}

.customSorterWrapper {
  margin-right: 2px;
}

.customSorterImg {
  color: #bfbfbf;
  cursor: pointer;
}

.activSort {
  color: #1890ff;
  cursor: pointer;
}

.filterActiv {
  background-color: #1890ff;
  height: 7px;
  width: 7px;
  position: absolute;
  top: 12px;
  right: -10px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}