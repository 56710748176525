.ant-card-body, .ant-card-small {
  padding: 2px 2px 8px 2px !important;
}

.ant-card {
  margin: 5px;
  background-color: rgb(255, 255, 255) !important;
}

.ant-card-head {
  background-color: blue;
}

.ant-layout {
  border-radius: 0.35rem 0.35rem 0 0;
}

.ant-card-head-wrapper  {
  position: relative;
}

.ant-card-body {
  background-color: rgb(255, 255, 255);
}

.ant-card-bordered {
  border: 3px solid rgb(152, 202, 255);
}

.userName {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  bottom: 12px;
  left: 30px;
  margin-left: 0px;
  color: #000000;
  font-size: 13px;
  font-weight: bold;
}