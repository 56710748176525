.DirectoryPageWrapper {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.DirectoryPage__title {
  padding: 20px 0 10px 0;
  font-size: 16px;
  font-weight: bold;
}
