.container {
  width: 70%;
  margin: 0 auto;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.videoGrid {
  display: grid;
  gap: 10px;
}

.oneVideo { 
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
}

.twoVideos {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
}

.videoItem {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  border-radius: 15px;
  overflow: hidden;
  background-color: black;
}

.userName {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  font-size: 13px;
  font-weight: bold;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
}

.videoButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgb(76, 163, 255);
  display: none;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  filter: drop-shadow(0px 5px 3px #0000003f);
}

.videoItem:hover .videoButton {
  display: flex;
}

.fullScreenVideoGrid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  width: 100%;
  height: 100%;
  margin-bottom: 200px;
}

.fullScreenVideoItem {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  border: 3px solid #c6c6c6;
  border-radius: 15px;
  overflow: hidden;
  background-color: black;
}

.fullScreenVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.fixedButtons {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1000;
}

.button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  cursor: pointer;
  filter: drop-shadow(0px 8px 5px #00000057);
}

.button.endCall {
  background-color: red;
  width: 60px;
  height: 60px;
}

.button.settings {
  background-color: rgb(76, 163, 255);
}

.button.users {
  background-color: rgb(76, 163, 255);
}

.mobileOnly {
  background-color: white;
  color: black;
}

.userItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  transition: background-color 0.5s ease;
  border-radius: 10px;
  outline: 1px solid rgb(224, 224, 224);
  margin-bottom: 10px;
}

.userItem:hover {
  background-color: rgb(233, 243, 255);
}

.userItem.currentUser {
  border: 2px solid rgb(76, 163, 255);
}

.userInfo {
  font-size: 16px;
  font-weight: 500;
  margin-left: 15px;
}

.userItem p {
  margin: 0;
}

@media (max-width: 700px) {
  .twoVideos {
    grid-template-columns: 1fr;
  }
  .videoGrid {
    grid-template-columns: 1fr;
  }
  .button {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
  .button.endCall {
    width: 50px;
    height: 50px;
  }
  .fixedButtons {
    gap: 5px;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .videoGrid {
    grid-template-columns: repeat(2, 1fr);
  }
  .twoVideos {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1200px) {
  .twoVideos {
    grid-template-columns: repeat(2, 1fr);
  }
  .videoGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}
